import * as L from 'leaflet'
import { FamilyTree } from './familyTree.js'
import {Point} from "leaflet/dist/leaflet-src.esm";
import treeLoading from "./treeLoading";

const ZOOM = -1;
const labelHeight = 18 * 2;
const labelWidth = 120 * 2;
let tree = null

function characterLink(bounds, descendant) {
	const link = new L
		.rectangle(bounds, {
			fillOpacity: 0,
			color: "#000000",
			descendantId: descendant.id,
			weight: getRectangleWeight(),
			lineJoin: 'square',
		})
		.on('click', () => {
			routie('descendant/'+descendant.id);
		});

	addTooltip(link, descendant);
	return link;
}

function addTooltip(character, descendant) {
	character.bindTooltip(
		getTooltipText(descendant),
		{
			className: 'tree-tooltip',
			opacity: 1,
			direction: 'bottom',
			offset: new Point(0, 192/3 / getRightZoomCoef()),
		},
	);
}

export function getTooltipText(descendant) {
	if (descendant.isMinted()) {
		return `${descendant.id}: ${descendant.name}`;
	} else {
		return `${descendant.id}: Unresearched`;
	}
}

const initMap = () => {
	const id = 'map';

	if (tree) {
		tree.off();
		tree.remove();
		document.getElementById(id).remove();
		const g = document.createElement("div");
		g.setAttribute("id", id);
		document.getElementById("map-container").appendChild(g);
	}

	tree = L.map(id, {
		crs: L.CRS.Simple,
		zoomSnap: 0,
		zoom: ZOOM,
		minZoom: -3,
		maxZoom: .8,
		inertia: true,
		maxBoundsViscosity: 0.9,
		scrollWheelZoom: false,
  		scrollWheelPan: true,
		gestureHandling: true,
	});

	return tree;
}

export const drawMap = (mapSize, descendants) => {
	const linksPayloads = [];
	const lines = [];

	const tree = initMap();
	let m = document.querySelectorAll('#map')[0];
	L.DomEvent.on(m, 'wheel', function (e) {
		if (e.metaKey || e.ctrlKey) {
            e.preventDefault();
            tree.scrollWheelZoom.enable();
        } else {
			tree.scrollWheelZoom.disable();
			let x = e.deltaX;
			let y = e.deltaY * 4;

			tree.stop();

			tree.panBy([x, y]);
		}
	})

	L.Map.ScrollWheelPan = L.Map.ScrollWheelZoom.extend({
		_performZoom: function (e) {
			var map = this._map;
			//map.stop(); // stop panning and fly animations if any
		}
	});

	L.Map.addInitHook('addHandler', 'scrollWheelPan', L.Map.ScrollWheelPan);

	var yx = L.latLng;

	function xy(x, y) {
		if (L.Util.isArray(x)) { // When doing xy([x, y]);
			return yx(x[1], x[0]);
		}
		return yx(y, x); // When doing xy(x, y);
	}

	function portraitBounds(corner, dimensions) {
		return [xy(corner[0] - dimensions[0] / 2, corner[1]), xy(corner[0] + dimensions[0] / 2, corner[1] - dimensions[1])];
	}

	function xPos(idx, length) {
		let ret = 0;
		const size = dimensions[0] + pad;
		const xMod = (mapSize[0] * 0.5) - size;
		if (length % 2 == 0) { // even
			ret = size * idx + xMod - size * ((length - 1) / 2)
		} else { // odd
			ret = size * idx + (xMod + size * 0.5) - size * (length / 2)
		}
		return ret;
	}

	function getLabelX(idx, length) {
		return xPos(idx, length) - dimensions[0]/2 - ((pad / 16) * Math.pow(tree.getZoom(), 3) * 2 * -1)  - (labelWidth * getZoomWithLimit() * -1);
	}

	function moveMemberOfCouple(descendant) {
		let ret = 0;
		if (descendant.spouse) {
			ret = fam.sortCouple(descendant.generation, descendant.id, descendant.spouse) * pad * 0.5;
		}
		return ret;
	}

    function xForDescendant(descendant, generation) {
        return moveMemberOfCouple(descendant) + xPos(descendant.column, generation.length)
    }

	function yForGeneration(generation) {
		return mapSize[1] - ((generation - 1) * (3 * pad + dimensions[1])) - pad;
	}

	function yForLabel(generation) {
		if (generation - 1 === 0) {
			return mapSize[1] - (pad/2 + dimensions[1]/2/ getZoomWithLimit() * -1 - (labelHeight * getZoomWithLimit() * -1)/2);
		} else {
			return mapSize[1] - (generation - 1) * (3 * pad + dimensions[1]) - (pad + dimensions[1]/2 - (labelHeight * getRightZoomCoef() )/2)
		}
	}
	
	var bounds = [xy(-25, -26.5), xy(25 + mapSize[0], 25 + mapSize[1])];
	//L.imageOverlay(url+'DeN7zDK.jpeg', bounds).addTo(tree);
	tree.setMaxBounds(bounds);

	const pad = 72;

	const dimensions = [108 * 2, 192 * 2]
	var iY = 0;

	const drawNode = (d, gen, rowPosition) => {
		let height = yForGeneration(d.generation);

		const corner = [xForDescendant(d, gen), height];
		const bounds = portraitBounds(corner, dimensions);
		
		if (d.mother || d.father) {
			const from = xy(corner[0], height)
			const to = xy(corner[0], height + 50)
			lines.push(L.polyline([from, to], lineOpts).addTo(tree));
		}
		const kids = d.children();
		if (kids && kids.length > 0) {
			const from = xy(corner[0], height - dimensions[1])
			const to = xy(corner[0], height - dimensions[1] - 45)
			lines.push(L.polyline([from, to], lineOpts).addTo(tree));

			if (d.isLeftOfSpouse() > 0) {
				const fromSpouse = xy(corner[0] + dimensions[0], height - dimensions[1] - 45);
				lines.push(L.polyline([fromSpouse, to], lineOpts).addTo(tree));

				const leftKid = fam.get(kids[0]);
				const rightKid = fam.get(kids[kids.length - 1]);
				const kidGen = fam.generations[leftKid.generation];
				const fromLeft = xy(xForDescendant(leftKid, kidGen), height - dimensions[1] - 165);
				const fromRight = xy(xForDescendant(rightKid, kidGen), height - dimensions[1] - 165);

				// line dropping from parents
				const fromMid = xy(corner[0] + (dimensions[0] * 0.5), height - dimensions[1] - 45);
				const toMid = xy(corner[0] + (dimensions[0] * 0.5), height - dimensions[1] - 105);
				lines.push(L.polyline([fromMid, toMid], lineOpts).addTo(tree));

				
				const childMid = xy((fromLeft.lng+fromRight.lng)*0.5, toMid.lat);
				if (Math.abs(childMid.lng - toMid.lng) > dimensions[1] * 0.25) {
					// draw horizontal towards the child midpoint
					lines.push(L.polyline([toMid, childMid], lineOpts).addTo(tree));
					// and down
					lines.push(L.polyline([childMid, xy(childMid.lng, childMid.lat-60)], lineOpts).addTo(tree));
				} else {
					// draw straight down
					const newMid = xy(toMid.lng, toMid.lat - 60);
					lines.push(L.polyline([toMid, newMid], lineOpts).addTo(tree));
				}

				// connect kids
				lines.push(L.polyline([fromLeft, fromRight], lineOpts).addTo(tree));
			}
		}

		treeLoading.addImage(d.thumbnail());

		const img = L.imageOverlay(d.thumbnail(), bounds, { descendantId: d.id }).addTo(tree);
		const link = characterLink(bounds, d).addTo(tree);
		linksPayloads.push({
			link,
			descendant: d,
		});
	};

    const lineOpts = {
		color: "#808080",
		weight: getLinesWeight(),
		lineCap: 'square',
	};

	var fam = new FamilyTree(descendants);
	var iGen = 1;
	const zoomPayloads = [];

	fam.generations.forEach(gen => {
		let rowPosition = 0;
		const tmpDesc = fam.get(gen[0]);

		if (tmpDesc) {
			xForDescendant(tmpDesc, fam.getGeneration(iGen));
		}

		const genLabelCoords = xy(getLabelX(tmpDesc.column, fam.getGeneration(iGen).length), yForLabel(iGen));

		const marker = L.marker(genLabelCoords, {
			icon: L.divIcon({
				html: 'Generation ' + iGen,
				className: 'generation-marker'
			})
		}).addTo(tree);

		zoomPayloads.push({
			marker,
			tmpDesc,
			iGen,
		});

		iGen++;
		gen.forEach(id => {
			const d = fam.get(id);
			if (typeof d == 'undefined') { return; }

            // save a reference back to the family - this is awkward. Better to initialize tree without descendants?
            d.setFamily(fam);

			// Set a random delay for loading offscreen assets.
			if (iY > 6) {
				setTimeout(drawNode(d, gen, rowPosition), Math.random() * 1000)
			} else {
				drawNode(d, gen, rowPosition);
			}

			iY++;
			rowPosition++;
		});
	});
	window.fam = fam;

	const markers = document.getElementsByClassName("generation-marker");

	tree.on("zoomstart", function(ev) {
		for(let marker of markers) {
			marker.style.visibility = "hidden";
		}
	});

	tree.on("zoomend", function(ev){
		zoomPayloads.forEach((paylaod) => {
			const {
				marker,
				tmpDesc,
				iGen,
			} = paylaod;

			const genLabelCoords = xy(getLabelX(tmpDesc.column, fam.getGeneration(iGen).length), yForLabel(iGen) );
			marker.setLatLng(genLabelCoords);
		})

		for(let marker of markers) {
			marker.style.visibility = "";
		}
	});

	tree.on("zoomstart", () => hideTooltips(linksPayloads));
	tree.on("zoomend", () => showTooltips(linksPayloads));

	tree.on("zoomend", () => {
		updateLines(lines, getLinesWeight);
		updateLines(linksPayloads.map((p) => p.link), getRectangleWeight);
	});

	// UI navigation
	// disable scroll wheel zoom and pan instead.
	tree.scrollWheelZoom.disable();

	createZoomControl(tree);

	// Starting view
	tree.setView(xy(mapSize[0] / 2, mapSize[1] - 100), ZOOM);
	return tree;
}

function updateLines(lines, getWeight) {
	lines.forEach((line) => {
		line.setStyle({
			weight: getWeight(),
		});
	});
}

function hideTooltips(linksPayloads) {
	const links = linksPayloads.map((p) => p.link);
	links.forEach(((link) => link.unbindTooltip()));
}

function showTooltips(linksPayloads) {
	linksPayloads.forEach((p) => addTooltip(p.link, p.descendant));
}

function getRightZoomCoef() {
	const zoom = tree.getZoom();
	let coef = zoom;
	if(coef > -1) {
		const shiftedZoom = zoom + 2;
		coef = -1 / shiftedZoom;
	}
	return -coef;
}

function getLinesWeight() {
	return 3 / getRightZoomCoef();
}

function getRectangleWeight() {
	return 4 / getRightZoomCoef();
}

function getZoomWithLimit() {
	return tree.getZoom() < -1 ? tree.getZoom() : -1;
}

function createZoomControl(tree) {
	// Create the control and add it to the map;
	const control = L.control.zoom();
	control.addTo(tree);

	// Call the getContainer routine.
	const htmlObject = control.getContainer();
	// Get the desired parent node.
	const container = document.getElementsByClassName('zoom-container')[0];

	// Finally append that node to the new parent, recursively searching out and re-parenting nodes.
	container.innerHTML = '';
	container.appendChild(htmlObject);
}