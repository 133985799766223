<section class="container collection-section">
    <h1 class="collection-section__title">
        <span class="text text_type_h2 text_accented">
            {{title}}
        </span>
    </h1>
    <ul class="collection-section__gallery flow-gallery">
        {{#each collection}}
            {{#if isPainting}}
                <li class="collection-section__item box">
                    <a class="collection-section__link box__content" href="#painting/{{{ paintingId }}}">
                        <img class="collection-section__img" src="{{{ paintingUrl }}}" alt="painting">
                    </a>
                    <div class="collection-section__caption tippy leaflet-tooltip tree-tooltip leaflet-zoom-animated leaflet-tooltip-bottom" style="opacity: 1">
                        {{getTooltipText this}}
                    </div>
                </li>
            {{else}}
                {{#if isMinted}}
                    <li class="collection-section__item box">
                        <a class="box__content collection-section__link" href="#descendant/{{{ id }}}">
                            <video class="gallery-player" playsinline>
                                <source src="{{ url }}/Videos/{{ fileName }}.crf26.mp4" type="video/mp4">
                            </video>
                        </a>
                        <div class="collection-section__caption tippy leaflet-tooltip tree-tooltip leaflet-zoom-animated leaflet-tooltip-bottom" style="opacity: 1">
                            {{getTooltipText this}}
                        </div>
                    </li>
                {{else}}
                    <li class="collection-section__item box">
                        <a class="box__content collection-section__link" href="#descendant/{{{ id }}}">
                            <img class="collection-section__img " src="{{{ thumbnail }}}" alt="painting">
                        </a>
                        <div class="collection-section__caption tippy leaflet-tooltip tree-tooltip leaflet-zoom-animated leaflet-tooltip-bottom" style="opacity: 1">
                            {{getTooltipText this}}
                        </div>
                    </li>
                {{/if}}
            {{/if}}
        {{/each}}
    </ul>
</section>