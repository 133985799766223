<div class="box extras-section__block">
    <div class="box__content photos">
        <h3 class="photos__title"><span class="text text_type_body-bold">My Photos</span></h3>
        <ul class="photos__gallery">
            <li class="photos__item"><img class="photos__img" src="{{ layer1Img }}" alt="photo"></li>
            <li class="photos__item"><img class="photos__img" src="{{ layer2Img }}" alt="photo"></li>
            <li class="photos__item"><img class="photos__img" src="{{ layer3Img }}" alt="photo"></li>
            <li class="photos__item"><img class="photos__img" src="{{ layer4Img }}" alt="photo"></li>
            <li class="photos__item"><img class="photos__img" src="{{ layer5Img }}" alt="photo"></li>
            <li class="photos__item"><img class="photos__img" src="{{ layer6Img }}" alt="photo"></li>
        </ul>
        <p class="photos__description"><span class="text text_type_body-reg">Photos of the things I love taken with my digital camera</span></p>
    </div>
</div>