import mapAddonId from "./mapAddonId";
import constants from "./constants";
import descendantIdToPaintingIdsDictionary from './painting_mapping.json';

function createS3PaintingUrl(paintingId) {
    const idData = mapAddonId(paintingId);
    const paintingsIds = descendantIdToPaintingIdsDictionary[idData.descendantId];
    const id = paintingsIds[idData.iteration - 1];

    return `${constants.S3_URL}/Paintings/Full+res/painting+${id}.jpg`;
}

export default createS3PaintingUrl;