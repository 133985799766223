const pages = {
    currentPage: null,
    setPage(value) {
        this.currentPage = value;
    },
};

export const Page = {
    ENTRY: 'ENTRY',
    TREE: 'TREE',
    CHARACTER: 'CHARACTER',
    COLLECTION: 'COLLECTION',
    ABOUT: 'ABOUT',
    EXTRA: 'EXTRA',
    PAINTING: 'PAINTING',
    ERROR: 'ERROR',
};

export default pages;