<article class="portrait portrait-section__preview">
    <div class="box">
        <div class="box__content">
            <video class="portrait__video portrait__video_vertical" id="player" playsinline>
                <source src="{{ injected.portraitSmallVideoUrl }}" type="video/mp4"/>
            </video>
        </div>
    </div>
    <div class="portrait__info">
        <div>
            <p>
                <span class="text text_type_body-bold text_accented">Full resolution portrait:</span><!--
             --><span class="portrait__full-resolution-links">
                    <a class="text text_type_body-link" href="{{ injected.portraitBigVideoUrl }}">video</a><!--
                 --><span class="text text_type_body-reg"> / </span><!--
                 --><a class="text text_type_body-link" href="{{ injected.portraitImgUrl }}">still</a>
                </span>
            </p>
            <p><span class="text text_type_body-reg">View on </span><a class="text text_type_body-link" href="{{ objktLink }}" target="_blank">objkt.com</a></p>
        </div>
        <button class="portrait__volume-button portrait__volume-button_state_off" id="volumeButton">
            <img class="portrait__turned-on-icon" src="{{injected.soundImg}}" alt="sound on">
            <img class="portrait__turned-off-icon" src="{{injected.soundMutedImg}}" alt="portrait preview">
        </button>
    </div>
</article>