import Plyr from 'plyr';
import mp4Controls from "./mp4Controls";
import initMp4Controls from './initMp4Controls';

function initEntryPlayer () {
  const id = 'entry-player';
  if (!document.getElementById(id)) return;

  const player = new Plyr(`#${id}`, {
    autoplay: false,
    volume: 1,
    controls: mp4Controls,
    seekTime: 3,
    hideControls: false,
    fullscreen: {
      enabled: false,
    },
    muted: true,
    loop: {
      active: true,
    },
    clickToPlay: true,
  });

  player.volume = 1;

  const video = player.elements.wrapper.querySelector(`video#${id}`);
  video.addEventListener('loadeddata', forcePlay, false);

  initMp4Controls(player);

  function forcePlay() {
    if (!document.getElementById(id)) return;
    player.play().catch();
  }
}

export default initEntryPlayer;