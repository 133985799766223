export const IS_VISITED = 'IS_VISITED';

const store = {
    getIsVisited() {
        return localStorage.getItem(IS_VISITED) === 'true';
    },
    setIsVisited(value) {
        localStorage.setItem(IS_VISITED, value);
    }
};

export default store;