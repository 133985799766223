import { DAppClient, NetworkType, TezosOperationType } from "@airgap/beacon-sdk";
import { char2Bytes } from '@taquito/utils';
import { SigningType } from '@airgap/beacon-sdk';
import { WalletService } from './wallet_service.js'
import constants from './constants';
import {getFA2AddonsBigmaps, getFA2Bigmaps, getMinterBigmaps} from "./bigmaps";
import getIpfsHash from "./getIpfsHash";
import mapBytesToIpfsLink from "./mapBytesToIpfsLink";

let MINTER, FA2, FA2ADDON, network, domainContractAddress;
if (process.env.NODE_ENV === 'production') {
    MINTER = 'KT1L4nUufQVMBkSVm6yeykKWsGQ4ztFnJN7c';
    FA2 = 'KT1BEMNH2hJUgYed4ySyZKNdFsPv6WhZHtCg';
    FA2ADDON = 'KT1WCnkia1783o9PC9vekCrXAKFujPCeYjC8';
    domainContractAddress = 'KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS';
    network = { type: NetworkType.MAINNET };
} else {
    MINTER = 'KT1BiULRmRRHraF7iQ1VPwoaXg5g18DR62Fh';
    FA2 = 'KT19cBgaHe9j5fQYE2TqEC5MkdVvqiJfcRwr';
    FA2ADDON = 'KT1HN2JRCc2P6eBcLqG4NWMmXqX9dg5cGMPV';
    domainContractAddress = '';
    network = { type: NetworkType.KATHMANDUNET };
}

const ADMINS = [
    'tz1cTS1WwovU7SC783xgJxZrzr151mcshmNi', // ir8prim8
    'tz1XQBQVE7dknxR8E7nxEf8isRh1mmp7Nr34', // Kim Laughton
    'tz1ThUqrixKAUEHK58C8cWXpFkbbsSncKxn5', // Brian Brian
    'tz1Kku5ojRzHzD1oRtb6FDZgWvRjedfF7yaq', // Landlines
    'tz1YBe1aEX6h2KLScbna3SHGz3qGjpZJ5sqe', // Chainsaw
    'tz1cmFRjwdQxTHJ98uKNRgZajsuju5RgHZrP', // swellinteractive
    'tz1LzPmBeTUULAsTwJmHsNrsE8QrUTfNRZJo',
    'tz1MxM9KMcANmDpfzjGiHsxMyX1euCkdKUV8', // ediv
];
let address;
let senderId;

const taquito = new WalletService(network.type);
window.taquito = taquito;
window.contracts = {
    MINTER,
    FA2,
    FA2ADDON,
    domainContractAddress,
};

async function logout() {
    await window.taquito.wallet.client.setActiveAccount();
    await window.taquito.wallet.client.removeAllAccounts();
    await updateActiveAccount();
    address = null;
    senderId = null;
}
window.logout = logout;

async function connectWallet() {

    try {
        console.log("Requesting permissions...");
        const permissions = await windows.taquito.requestPermission();
        console.log("Got permissions:", permissions.address);
        sign(permissions.address);
    } catch (error) {
        console.log("Got error:", error);
    }

}

const isLocalEnv = () => {
    return window.location.origin.startsWith('http://localhost');
}

const formatAddress = (address) => {
    return address.slice(0, 7) + '...' + address.slice(-4)
}

export var signedPayload;
const sign = async (userAddress) => {
    // Only allow admins to sign/login.
    if (!ADMINS.includes(userAddress)) { return; }

    const ISO8601formatedTimestamp = new Date().toISOString();
    const input = "My voice is my passport. Verify me."

    const formattedInput = [
        'Tezos Signed Message: ',
        input,
      ].join(' ');

    // The bytes to sign
    const bytes = char2Bytes(formattedInput);
    const payloadBytes = '05' + '0100' + char2Bytes(String(bytes.length)) + bytes;

    // The payload to send to the wallet
    const payload = {
        signingType: SigningType.MICHELINE,
        payload: payloadBytes,
        sourceAddress: userAddress,
    };

    // The signing
    signedPayload = await window.taquito.wallet.client.requestSignPayload(payload);

    // The signature
    console.log("Signed: " + signedPayload.signature)
}

// Display the active account in the UI
export const updateActiveAccount = async () => {
    const active = document.getElementById('activeAccount')
    const activeValue = document.getElementById('activeAccountValue')
    return taquito.wallet.client.getActiveAccount().then(async (activeAccount) => {
        if (activeAccount) {
            address = activeAccount.address;
            senderId = activeAccount.senderId;
            const domain = await window.taquito.fetchTezosDomainFromAddress(activeAccount.address);
            const finalName = domain !== activeAccount.address
                ? domain
                : formatAddress(activeAccount.address);
            activeValue.innerHTML = finalName;
            active.classList.remove('is-hidden')
            document.getElementById('sync-1').style.display = 'none';
            try {
                if (!window.authenticated()) { sign(address); }
            } catch(e) {
                await logout();
            }
        } else {
            address = undefined;
            senderId = undefined;
            activeValue.innerHTML = '';
            active.classList.add('is-hidden')
            document.getElementById('sync-1').style.display = 'block';
        }
        window.loadUI();
    })
}

export const getSenderId = () => {
    return senderId;
}

export const getAddress = () => {
    return address;
}

const isTestnet = () => {
    return window.taquito.isTestnet;
}

const getApiUrl = () => {
    return isTestnet() ? 'https://api.kathmandunet.tzkt.io/' : 'https://api.tzkt.io/';
}
window.getApiUrl = getApiUrl;

let price = 0;

export function formatPriceForView(price) {
    return price / 10 ** 6
}

export const loadContractStorage = () => {
    const url = getApiUrl();
    return fetch(`${url}v1/contracts/${MINTER}/storage`)
        .then(response => response.json())
        .then(data => {
            price = data['price'] / 10 ** 6
        })
}

export const getPrice = () => { return price; }

export const loadMintedTokens = async () => {

    // https://api.ghostnet.tzkt.io/v1/tokens/balances?token.contract=KT1EgSfhgcwFPsuoyEnwrDM2sGydwsfQ3hnQ&balance.eq=1
    const url = getApiUrl();
    return fetch(`${url}v1/tokens/balances?token.contract=${FA2}&balance.eq=1&limit=10000 `)
        .then(response => response.json())
        .then(data => {
            return data.map(mapBalanceToToken)
        })
}

export const loadOwnedPaintings = async () => {
    if (!address) return [];
    const url = getApiUrl();
    return fetch(`${url}v1/tokens/balances?token.contract=${FA2ADDON}&balance=1&limit=10000&account=${address}`)
        .then(response => response.json())
        .then(data => {
            return Promise.all(data.map(async (d, i) => {
                d.token.metadata = await loadPaintingMetadataReliably(d.token.tokenId);
                return mapBalanceToToken(d);
            }))
        });
}

export const loadPaintingById = async (id, a) => {
    const url = getApiUrl();

    return fetch(`${url}v1/tokens/balances?token.contract=${FA2ADDON}&balance=1&token.tokenId=${id}`)
        .then(response => response.json())
        .then(async data => {
            data[0].token.metadata = await loadPaintingMetadataReliably(id);
            return mapBalanceToToken(data[0]);
        });
}

function mapBalanceToToken(data) {
    return {
        "id": data.token.tokenId,
        "metadata": data.token.metadata,
        "account": data.account.address,
    };
}

export const getTokenMetadataHash = async (tokenId) => {
    // FA2 ledger bigmap
    const mapKey = (await getFA2Bigmaps()).token_metadata;
    const url = `${getApiUrl()}v1/bigmaps/${mapKey}/keys/${tokenId}`;
    return fetch(url)
    .then(response => response.json())
        .then(data => {
            const b = data['value']['token_info']['']
            return mapBytesToIpfsLink(b);
        })
}


export const getPaintingMetadataUrl = async (tokenId) => {
    const mapKey = (await getFA2AddonsBigmaps()).token_metadata;
    const url = `${getApiUrl()}v1/bigmaps/${mapKey}/keys/${tokenId}`;
    return fetch(url)
        .then(response => response.json())
        .then(data => {
            const b = data['value']['token_info']['']
            return mapBytesToIpfsLink(b);
        });
}

export const getAllTokenMetadataHashes = async () => {
  // FA2 ledger bigmap
  const mapKey = (await getFA2Bigmaps()).token_metadata;
  const url = `${getApiUrl()}v1/bigmaps/${mapKey}/keys?limit=1000`;
  const response = await fetch(url);
  const json = await response.json();
  return json;
}

export const loadTokenMetadata = (ipfsHash) => {
    // localhost can't use s3 endpoint
    const altUrl = `${constants.IPFS_URL}${ipfsHash}`;
    const url = `${constants.S3_URL}/Meta/${ipfsHash}.json`;

    return fetch(altUrl)
    .catch(err => {
        if (altUrl) {
            console.error(err);
        } else {
            console.log(err);
            // try the ipfs endpoint for local dev
            // return loadTokenMetadata(ipfsHash, `https://cloudflare-ipfs.com/ipfs/${ipfsHash}`)
        }
    })
    .then(response => response.json());
}

async function loadPaintingMetadataReliably(id) {
    const url = await getPaintingMetadataUrl(id);
    const response = await fetch(`${constants.IPFS_URL}${getIpfsHash(url)}`);
    const metadata = await response.json();
    return metadata;
}

export const loadAddons = () => {
    const url = `${getApiUrl()}v1/contracts/${MINTER}/storage`;
    return fetch(url)
        .then(response => response.json())
        .then(r => r['addon_info'])
        .then(r => {
            Object.keys(r).forEach(k => {
                r[k]['id'] = k;
                r[k]['price'] = r[k]['price'] / 1000000; // convert to tez
            })                        
            return r;
        })
}

/**
 * Bigmap 32705 store all owners if each nft
 * It's value is a boolean of whether the ownership active
 */
export const loadOwnedTokens = async () => {
    // FA2 ledger bigmap
    const mapKey = (await getFA2Bigmaps()).ledger;
    return fetch(`${getApiUrl()}v1/bigmaps/${mapKey}/keys?key.address=${address}&value=1`)
        .then(response => response.json())
        .then(data => {
            return data.map(d => { return d['key']['nat'] })
        })
}

/**
 * Addon data:
 * 1000300010001 ==
 *  0003 (digits 1 to 4 are the descendant number)
 *  0001 (digits 5 to 8 are the addon number)
 *  0001 (digits 9 to 12 are the iteration number this is only used when we have addons like paintings where you might have 
 *      several with the same addon type. for hobbies it should always be 0001)
 * "1000300010001": false means that the update has not been processed by the script, and thus is not yet reflected in the 
 *      descendants description and attributes.
 * "1000300010001": true means the update has been processed by the script (the bigmap update and the token metadata update 
 *      are done in the same txn in the script, so either they both fail or they both suceeed).
 */
export const loadOwnedAddons = async (addons, descendantId, firstName) => {
    // FA2 minter addons bigmap
    const mapKey = (await getMinterBigmaps()).addons;
    return fetch(`${getApiUrl()}v1/bigmaps/${mapKey}/keys?key=${descendantId}`)
        .then(response => response.json())
        .then(data => {
            const ret = data.map(d => {
                return Object.keys(d.value).map(a => {
                    const addon = addons[Number(a.slice(5, 9))]
                    let ret = Object.assign({}, addon, {
                        iteration: Number(a.slice(9)),
                        addText: !d.value[a]
                    });
                    ret.description = ret.description.replace('xxx', firstName);
                    return ret;
                })
            })
            return ret && ret.length > 0 ? ret.pop() : ret;
        })
}

export const loadAccount = (id) => {
    return fetch(`${getApiUrl()}v1/accounts/${id}`)
    .then(response => response.json())
    .then(data => {
        return data['alias'] || id.slice(0,4) + '..' + id.slice(-4);
    });
}