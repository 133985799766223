<section class="painting-section container {{class}}">
    <a href="#painting/{{{ paintingId }}}">
        <img class="painting-section__img" src="{{paintingUrl}}" alt="painting">
    </a>
    <h2 class="painting-section__title"><span class="text text_type_h2 text_accented">~ {{paintingName}} ~</span></h2>
    <p>
        <span class="text text_type_body-reg">A painting by </span><!--
     --><a href="#descendant/{{descendantId}}" class="text text_type_body-link">{{{descendantName}}}</a><!--
     --><span class="text text_type_body-reg"> owned by </span><!--
     --><a href="https://objkt.com/profile/{{ paintingOwnerAccount }}" target="_blank" class="text text_type_body-link">{{paintingOwnerName}}</a>
    </p>
    <p class="painting-section__view-link"><span class="text text_type_body-reg">View on </span><a class="text text_type_body-link" href="https://objkt.com/asset/the-descendants-extras/{{paintingId}}" target="_blank">objkt.com</a></p>
</section>