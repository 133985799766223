function getOnVideoReadyPromise(url) {
    const video = document.createElement('video');
    video.src = url;

    // needed to work on IOS
    video.muted = true;
    video.autoplay = true;

    if (video.readyState >= 3) return Promise.resolve();

    return new Promise((resolve) => {
        video.addEventListener("loadeddata", function (e) {
            resolve();
        });
    });
}

export default getOnVideoReadyPromise;