let nextValue = 0;
let lastUpdate = 0;
let timeout = null;

function initMinimapUpdating(tree, minimap, mapSize) {
    tree.on('move', move);
    tree.on('moveend', move);

    function move () {
        const bound = tree.getBounds();
        const height = mapSize[1];

        let lat = (bound._northEast.lat + bound._southWest.lat) / 2;
        if (bound._northEast.lat >= height) {
            lat = height;
        }
        if (bound._southWest.lat <= 0) {
            lat = 0;
        }

        const newValue = lat / height * 100;
        setMinimapValue(minimap, newValue);
    }
}

function setMinimapValue(minimap, value) {
    const limit = 25;
    const pause = 1000 / limit;
    const now = Date.now();
    const difference = now - lastUpdate;

    if (difference > pause) {
        lastUpdate = now;
        minimap.value = value;
    } else if (!timeout) {
        nextValue = value;
        timeout = setTimeout(
            () => {
                lastUpdate = Date.now();
                minimap.value = nextValue;
                timeout = null;
            },
            difference,
        );
    } else {
        nextValue = value;
    }
}

export default initMinimapUpdating;