import { getTokenMetadataHash, loadTokenMetadata } from './dapp.js'
import constants from "./constants";
import getIpfsHash from "./getIpfsHash";

export class Descendant {

    /**
     * @param {Object} data - from the json file 
     * @param {string} url - prefix to add to media URLs.
     * @param {Object} metadata - metadata object
     * @param {Array} collection - array of FA2 ids owned by current user
     */
    constructor(data, url, metadata, account, collection) {
        this.id = Number(data['Name']);
        this.column = Number(data['Column order']);
        this.father = Number(data['Father']);
        this.generation = Number(data['Generation']);
        this.mother = Number(data['Mother']);
        this.spouse = Number(data['Spouse']);

        this.populateMetadata(metadata)

        this.fileName = this.name.replaceAll(' ', '_');

        this.family = [];
        this.spouseName = '';

        this.url = url;
        this.isMine = collection.includes(String(this.id));

        this.account = account;
    }

    async accountAlias() {
        return await loadAccount(this.id);
    }

    populateMetadata(data) {
        
        const _attr = (name) => { return data.attributes.filter((a) => { return a.name == name }).pop()['value'] }
        this.name = this.cleanName(data.name)
        this.firstName = this.name.split(' ')[0];

        this.ageAtDeath = Number(_attr('Age at death'));
        this.causeOfDeath = _attr('Cause of death');
        this.gender = _attr('Gender');
        this.occupation = _attr('Occupation');

        const lines = data.description.split("\n")
        this.lines = lines;

        this.bio = this._getLineOfField('Bio:');
        this.notableWorks = this._getLineOfField('Notable works:');
        this.legacy = this._getLineOfField('Legacy:');

        if (this.notableWorks == 'unknown') { this.notableWorks = undefined; }
        if (this.legacy == 'unknown') { this.legacy = undefined; }

        this.addons = [];
        this.addonsAvailable = [];
    }

    _getLineOfField(fieldName) {
        const line = this.lines
            .filter((l) => l)
            .find((l) => l.startsWith(fieldName));

        if (line) {
            return line.replace(`${fieldName} `, '').trim();
        } else {
            return undefined;
        }
    }

    cleanName(name) {
        return name.split(' [')[0]
    }

    thumbnail() {
        if (this.isMinted()) {
            return `${this.url}/Stills/${this.fileName}.sm.jpg`;
        }
        return `${this.url}/Blurred/sm${this.id}.jpg`;
    }

    isMinted() {
        return true
    }

    children() {
        return this.family.childrenOf(this.id);
    }

    isLeftOfSpouse() {
        var ret;
        if (!this.spouse) {
            ret = false;
        } else {
            return this.family.sortCouple(this.generation, this.id, this.spouse);
        }
        return ret;
    }

    _familyName(id, label) {
        //console.log(`_familyName ${id} ${label}`)
        if (id) {
            const person = this.family.get(id);
            if (!person) {
                console.error(`No ${label} for ${this.name} ${this.id}`)
                return undefined;
            }
            return person.name;
        }
    }

    /**
     * Set properties with family member names - will be used by handlebars.
     */
    setFamily(family) {

        this.family = family;
        this.spouseName = this._familyName(this.spouse, 'spouse');
        this.motherName = this._familyName(this.mother, 'mother');
        this.fatherName = this._familyName(this.father, 'father');
        this.childrenNames = [];
        this.children().forEach(kid => {
            this.childrenNames[kid] = this._familyName(kid, 'child');
        });

    }

    setAddons(addonsAvailable, myAddons) {
        this.addons = myAddons;
        console.log('setAddons')
        console.log(this.addons);
        // re-run to update totals
        this.addonsAvailable = this.availableAddons(addonsAvailable);
        this.displayHobbies = this.addonsAvailable.length > 0;
        this.isMaxHobbies = this._hobbies.length >= Descendant.HOBBIES_LIMIT;
        this.isPainter = !!this.addons.find((a) => a.attribute === constants.PAINTING_HOBBY_ATTRIBUTE);
        this.isPaintingMintingAvailable = this.isPainter && this.availablePaintingsAmount > 0;
    }

    availableAddons(addons) {
        let ret = [];
        let me = this;
        console.log('availableAddons', addons);
        
        Object.keys(addons).forEach((id) => {
            const addon = addons[id];
            console.log(addon);
            console.log(addon.id)

            // max_token_id - make sure not already owned
            const ownedCount = me.addons.filter(a => { return a.id == addon.id }).length,
                underMaxOwnedTest = Number(addon['max_token_id']) > ownedCount;
            console.log(`${id} maxOwned ${underMaxOwnedTest} ${addon['max_token_id']} > ${ownedCount}`)
            // min_age / max_age
            const ageTest = (me.ageAtDeath > Number(addon['min_age'])) && (me.ageAtDeath < Number(addon['max_age']));
            if (underMaxOwnedTest && ageTest) {
                ret.push(addon);
            }
        });
        return ret;
    }

    set addons(addons) {
        this._addons = addons;
        this._hobbies = this._addons.filter((a) => a['is_attribute']);
        this._paintings = this._addons.filter((a) => !a['is_attribute'] && a.id === constants.PAINTING_ADDON_ID);
        this._availableHobbiesAmount = Descendant.HOBBIES_LIMIT - this._hobbies.length;
        this._availablePaintingsAmount = Descendant.PAINTINGS_LIMIT - this._paintings.length;
    }

    get addons() {
        return this._addons;
    }

    get hobbies() {
        return this._hobbies;
    }

    get paintings() {
        return this._paintings;
    }

    get availableHobbiesAmount() {
        return this._availableHobbiesAmount;
    }

    get availablePaintingsAmount() {
        return this._availablePaintingsAmount;
    }

    toObject() {
        return {
            'Name': this.id,
            'Column order': this.column,
            'Father': this.father,
            'Generation': this.generation,
            'Mother': this.mother,
            'Spouse': this.spouse,
            'URL': this.url
        }
    }

    isUnindexed() {
        return false;
    }

}

Descendant.HOBBIES_LIMIT = 3;
Descendant.PAINTINGS_LIMIT = 3;

export class UnindexedDescendant {
    /**
    * @param {Object} data - from the json file 
    * @param {string} url - prefix to add to media URLs.
    */
    constructor(data, url) {
        this.id = Number(data['Name']);
        this.column = Number(data['Column order']);
        this.father = Number(data['Father']);
        this.generation = Number(data['Generation']);
        this.mother = Number(data['Mother']);
        this.name = `Descendant #${data['Name']} - waiting to be indexed`
        this.spouse = Number(data['Spouse']);
        this.url = url;
    }

    isMinted() {
        return false;
    }

    isUnindexed() {
        return true;
    }

    toObject() {
        return {
            'Name': this.id,
            'Column order': this.column,
            'Father': this.father,
            'Generation': this.generation,
            'Mother': this.mother,
            'Spouse': this.spouse,
            'URL': this.url
        }
    }
}

export class MysteryDescendant {
    /**
    * @param {Object} data - from the json file 
    * @param {string} url - prefix to add to media URLs.
    */
    constructor(data, url) {
        this.id = Number(data['Name']);
        this.column = Number(data['Column order']);
        this.father = Number(data['Father']);
        this.generation = Number(data['Generation']);
        this.mother = Number(data['Mother']);
        this.name = `Descendant #${data['Name']}`
        this.spouse = Number(data['Spouse']);
        this.url = url;
    }

    isMinted() {
        return false;
    }

    isUnindexed() {
        return false;
    }

    toObject() {
        return {
            'Name': this.id,
            'Column order': this.column,
            'Father': this.father,
            'Generation': this.generation,
            'Mother': this.mother,
            'Spouse': this.spouse,
            'URL': this.url
        }
    }
}

// Hook up the instance properties
Object.setPrototypeOf(MysteryDescendant.prototype, Descendant.prototype);
Object.setPrototypeOf(UnindexedDescendant.prototype, Descendant.prototype);

// Hook up the static properties
Object.setPrototypeOf(MysteryDescendant, Descendant);
Object.setPrototypeOf(UnindexedDescendant, Descendant);

/**
 * TODO - something is wrong with the sync approach here. Need to wait for all the descendants before 
 * drawing the tree.
 */
 export const parseDescendants = (data, url, tokenData, collection, tokenMetadata) => {

    window.minted = tokenData.map((d) => { return d.id })

    // this is indexed starting from 0 instead of 1 like the ids.
    return data.map(async (d) => {

        // console.log('d',d)
        // if (d.Name === '18') {
        //     console.log('!', data)
        //     // debugger;
        // }

        const is_minted = minted.includes(d['Name'])
        if (!is_minted) { return new MysteryDescendant(d, url) }

        // Get the token metadata payload
        const metadata = tokenData.filter((t) => { return t.id == d['Name'] })[0];
        if (typeof metadata.metadata == 'undefined') {
            // Is metadata available?
            return await indexDescendant(d, url, metadata.account, collection, tokenMetadata)
        }

        return new Descendant(d, url, metadata.metadata, metadata.account, collection);
    });
}

export const indexDescendant = async (d, url, account, collection, tokenMetadata) => {
    let hash;
    try {              
      const tokenId = d['Name'];
      const item = tokenMetadata.find((item) => item['value']['token_id'] == tokenId);      
      // hash = await getTokenMetadataHash(tokenId, item);      
      hash = item['value']['token_info']['']
        .match(/.{1,2}/g).reduce((acc,char)=>acc+String.fromCharCode(parseInt(char, 16)),"")      
      
    } catch (e) {
        console.warn(e);
        return new UnindexedDescendant(d, url)
    }
    const ipfs = getIpfsHash(hash);
    return loadTokenMetadata(ipfs)
        .then(data => {
            return new Descendant(d, url, data, account, collection);
        }).catch((err) => {
            console.warn(err);
            return new UnindexedDescendant(d, url)
        })
}