import { NetworkType } from "@airgap/beacon-sdk";
import { TezosToolkit, OpKind } from "@taquito/taquito";
import { BeaconWallet  } from "@taquito/beacon-wallet";
import { bytes2Char } from "@taquito/utils";

export class WalletService {
    constructor(network) {
        this.setNetwork(network);
    }

    setNetwork(network) {
        this.network = network;
        const networkInfo = this.getNetwork();
        this.wallet = new BeaconWallet({ name: 'The Descendants', preferredNetwork: network });
        this.taquito = new TezosToolkit(networkInfo['rpcUrl']);
        this.url = networkInfo['rpcUrl']

        if (this.network === NetworkType.JAKARTANET) {
            this.taquito._rpcClient.url = networkInfo.rpcUrl;
            this.taquito._rpcClient.chain = 'main';
        }

        this.isTestnet = network !== NetworkType.MAINNET;
        this.taquito.setWalletProvider(this.wallet);
    }

    getNetwork() {
        console.log(`getNetwork ${this.network}`)
        return {
            [NetworkType.MAINNET]: {
                type: NetworkType.MAINNET,
                name: 'Mainnet',
                rpcUrl: 'https://tezos-prod.cryptonomic-infra.tech/',
            },
            [NetworkType.GHOSTNET]: {
                type: NetworkType.GHOSTNET,
                name: 'Ghostnet',
                rpcUrl: 'https://rpc.ghostnet.teztnets.xyz/',
            },
            [NetworkType.KATHMANDUNET]: {
                type: NetworkType.KATHMANDUNET,
                name: 'Kathmandunet',
                rpcUrl: 'https://kathmandunet.tezos.marigold.dev/',
                chain: 'main'
            }
        }[this.network]
    }

    requestPermission(callback) {
        console.log('requestPermission')
        const opts = {
            network: this.getNetwork(),
        }
        return this.wallet.client
            .requestPermissions(opts)
            .then((permissions) => {
                console.log('permissions', permissions)
                if (callback) {
                    callback(permissions)
                }

            })
            .catch((error) => {
                console.log('error during permission request', error)
                throw error
            })
    }

    async getContractAwait(address) {
        return await this.taquito.wallet.at(address);
    }

    getContract(address) {
        const a = this.taquito.wallet.at(address);
        return a;
    }

    /**
     * price - set by loadContractStorage - may become invalid if a new price is reached.
     */
    async mint(minterAddress, tokenId, cost, showLoader) {
        try {
            const contract = await this.getContract(minterAddress);

            const op = await contract
                .methods['mint_descendant'](tokenId)
                .send({
                    amount: String(cost),
                });

            // notify and refresh
            showLoader();
            opModal(this.network, op)
            return op.confirmation();

        } catch (err) {
            console.error(err);
            return err;
        }
    }

    async mintAddon(minterAddress, cost, descendantId, addonId, iterationId, showLoader) {
        try {
            const contract = await this.getContract(minterAddress);

            const op = await contract.methods['mint_addon'](addonId, String(descendantId), iterationId)
                .send({ amount: String(cost) })

            showLoader();
            // notify and refresh
            opModal(this.network, op)
            return op.confirmation();
        } catch (err) {
            console.error(err);
            return err;
        }
    }

    /**
     * addonArray - array - [{addonId, iterationId, cost}]
     */
    async mintAddons(minterAddress, descendantId, addonArray, showLoader) {
        try {
            const contract = await this.getContract(minterAddress);

            var batchOps = []
            addonArray.forEach(addon => {
                batchOps.push({
                    kind: OpKind.TRANSACTION,
                    ...contract.methods['mint_addon'](addon.addonId, String(descendantId), addon.iterationId).toTransferParams({ amount: addon.cost }),
                    amount: addon.cost,
                    mutez: false,
                });
            })
            console.log(batchOps);
            var batchOp = this.taquito.wallet.batch(batchOps)
            const op = await batchOp.send();

            console.log(`Op hash ${op.opHash}`)
            showLoader();
            opModal(this.network, op);
            return op.confirmation();

        } catch (err) {
            console.error(err);
            return err;
        }
    }

    // the function returns the domain name if found or the provided address
    async fetchTezosDomainFromAddress(address) {
        try {
            const contract = await this.getContract(window.contracts.domainContractAddress)
            const storage = await contract.storage();
            const domain = await storage.store.reverse_records.get(address);

            if (domain) {
                return bytes2Char(domain.name);
            } else {
                return address;
            }
        } catch (e) {
            console.error(e);
            return address;
        }
    }
}