<section class="hobbies-section additional-section container">
    <div class="box hobbies-section__form">
        <div class="box__content hobbies-form">
            <h2 class="hobbies-form__title"><span class="text text_type_h2 text_accented">How did {{ firstName }} spend their time (<span class="hobbies-counter">{{ get this "availableHobbiesAmount" }}</span> remaining)?</span></h2>
            <ul class="checkbox-list">
                {{#each addonsAvailable }}
                    {{#if attribute }}
                      <li class="checkbox-list__item">
                          <label class="checkbox">
                              <input class="checkbox__element addHobbies" type="checkbox" name="{{{id}}}" value="1"><span class="text text_type_body-reg checkbox__label">{{ attribute }}</span>
                          </label>
                      </li>
                    {{/if }}
                {{/each}}
            </ul>
            <div class="hobbies-form__button-container">
                <button class="button" onclick="mintAddon({{id}})">Add hobbies</button>
            </div>
            <div>
                <p class="text text_type_body-italic hobbies-form__description">Up to three hobbies can be added for one tez each.</p>
                <p class="text text_type_body-italic hobbies-form__description">Selection is final, and will influence {{ firstName }}&#39;s narrative.</p>
                <p class="hobbies-form__last-p"><span class="text text_type_body-italic">Hobbies only available to Descendants older than 4</span></p>
            </div>
        </div>
    </div>
</section>