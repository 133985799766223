<div class="description__block">
    {{#if mother}}
        <p class="description__property">
            <span class="text text_type_body-bold text_accented description__property-name">Mother:</span><!--
             --><a class="text text_type_body-italic-link" href="#descendant/{{mother}}">{{ motherName }}</a>
        </p>
    {{/if}}
    {{#if father}}
        <p class="description__property">
            <span class="text text_type_body-bold text_accented description__property-name">Father:</span><!--
             --><a class="text text_type_body-italic-link" href="#descendant/{{father}}">{{ fatherName }}</a>
        </p>
    {{/if}}
    {{#if spouse}}
        <p class="description__property">
            <span class="text text_type_body-bold text_accented description__property-name">Spouse:</span><!--
             --><a class="text text_type_body-italic-link" href="#descendant/{{spouse}}">{{ spouseName }}</a>
        </p>
    {{/if}}
</div>