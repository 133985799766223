import Plyr from 'plyr';
import sound from './sound';

function initPlayer () {
  const id = 'player'
  if (!document.getElementById(id)) return;

  const player = new Plyr(`#${id}`, {
    autoplay: false,
    volume: 1,
    controls: [],
    fullscreen: {
      enabled: false,
    },
    muted: true,
    loop: {
      active: true,
    },
  });

  if (sound.isEnabled) {
    sound.enableSound(player);
  }

  player.on("ready",() => {
    updateVolumeButton();
    player
      .play()
      .catch(() => {
        sound.disableSound(player);
        player.play();
    }).then(()=> {
    });
  });

  player.on('volumechange', () => {
    updateVolumeButton();
  });

  const button = document.getElementById('volumeButton');

  button.addEventListener('click', () => {
    if (player.muted) {
      sound.enableSound(player);
    } else {
      sound.disableSound(player)
    }
  });

  function updateVolumeButton() {
    const stateOnClass = 'portrait__volume-button_state_on';
    const stateOffClass = 'portrait__volume-button_state_off'

    if (player.muted) {
      button.classList.add(stateOffClass);
      button.classList.remove(stateOnClass);
    } else {
      button.classList.add(stateOnClass);
      button.classList.remove(stateOffClass);
    }
  }
}

export default initPlayer;