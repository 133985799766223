<article class="description">
    <div class="description__header">
        <h2 class="description__title"><span class="text text_type_h2 text_accented">~ {{ name }} ~</span></h2>
        <p class="text text_type_body-reg">
            {{#if isUnindexed}}
                This family member is still being indexed, please check back soon.
            {{else}}
                Mint this family member to research their history.
            {{/if}}
        </p>
    </div>
    <div class="description__block">
        <p class="text text_type_body-reg">???</p>
    </div>
    <div class="description__block">
        <p class="description__property">
            <span class="text text_type_body-bold text_accented description__property-name">Occupation:</span><!--
          --><span class="text text_type_body-reg">???</span>
        </p>
    </div>
    <div class="description__block">
        <p class="description__property">
            <span class="text text_type_body-bold text_accented description__property-name">Age at death:</span><!--
         --><span class="text text_type_body-reg">???</span>
        </p>
        <p class="description__property">
            <span class="text text_type_body-bold text_accented description__property-name">Cause of death:</span><!--
         --><span class="text text_type_body-reg">???</span>
        </p>
    </div>
    {{> family-block}}
    {{> children-block }}
</article>