export class FamilyTree {
    constructor(descendants) {
        this.descendants = descendants;
        this.generations = [];

        for (var iX = 1; iX <= 104; iX++) {
            this.generations[iX] = this.descendants.filter(d => { return d.generation == iX })
                .sort((d1, d2) => { return d1.column - d2.column })
                .map(d => { return d.id });
        }
    }

    get(tokenId) {
        return this.descendants[tokenId - 1];
    }

    getGeneration(id) {
        return this.generations[id];
    }

    /**
     * 
     * @param {int} id1 - token id of member to move 
     * @param {int} id2 - token_id of the spouse
     */
    sortCouple(gen, id1, id2) {
        const g = this.generations[gen];
        return g.indexOf(id2) - g.indexOf(id1);
    }

    childrenOf(id) {
        const dlen = this.descendants.length;
        //console.log(`childrenOf ${id} len=${dlen}`)
        return this.descendants.filter(d => { return d.mother == id || d.father == id })
            .sort((d1, d2) => { return d1.column - d2.column })
            .map(d => { return d.id });
    }
}