const mp4Controls = `
<div class="plyr__controls plyr_not-started">
    <div class="plyr__controls-block">
        <button type="button" class="plyr__control plyr__play" aria-label="Play, {title}" data-plyr="play">
            <svg class="icon&#45;&#45;not-pressed" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5H6V16H8V15H10V14H12V13H14V12H16V11H17V10H16V9H14V8H12V7H10V6H8V5Z" fill="black"/>
            </svg>
        </button>
        <button type="button" class="plyr__control plyr__pause" aria-label="Play, {title}" data-plyr="play">
            <svg class="icon&#45;&#45;pressed" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 6H7V15H10V6Z" fill="black"/>
                <path d="M15 6H12V15H15V6Z" fill="black"/>
            </svg>
        </button>
        <button type="button" class="plyr__control plyr__stop" data-plyr="stop">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="7" y="6" width="8" height="9" fill="black"/>
            </svg>
        </button>
    </div>
    <button type="button" class="plyr__control plyr__volume" aria-label="Mute" data-plyr="mute">
        <span class="icon--pressed"></span>
        <span class="icon--not-pressed"></span>
    </button>
</div>
`;

export default mp4Controls;