<section class="extras-section container">
    <article class="extras-section__text-block extras-section__article">
        <h2 class="extras-section__title"><span class="text text_type_h2 text_accented">~EXTRAS~</span></h2>
        <div class="extras-section__block">
            <p class="extras-section__p"><span class="text text_type_body-reg">Here are things you may be interested in that didn’t fit into the rest of the website. Enjoy!</span></p>
            <p class="extras-section__p"><span class="text text_type_body-reg">**********</span></p>
            <p class="extras-section__p"><span class="extras-section__music-link"><a class="text text_type_body-link" href="{{ extrasSound }}" download>Download</a><span class="text text_type_body-reg"> the theme tune by </span><a class="text text_type_body-link" href="https://brianbrianbrianbrian.com/" target="_blank">Brian Tessler</a></span></p>
            <p class="extras-section__p"><span class="extras-section__video-link"><a class="text text_type_body-link" href="{{ extrasVideo }}" download>Download</a><span class="text text_type_body-reg"> the introduction video</span></span></p>
        </div>
    </article>
    <article class="extras-section__article">
        <div class="box">
            <div class="box__content gallery">
                <h3 class="gallery__title"><span class="text text_type_body-bold">Image Gallery</span></h3>
                <ul class="gallery__items-container">
                    <li class="gallery__item">
                        <a href="{{ coatOfArmsImg }}" target="_blank" class="gallery__img-container"><img class="gallery__img" src="{{ coatOfArmsThumbnail }}" alt="photo"></a>
                        <p class="gallery__comment"><span class="text text_type_body-reg">Coat of arms illustrated </span></p>
                        <p><span class="text text_type_body-reg">by </span><a class="text text_type_body-link" href="https://www.instagram.com/mikaelholz/" target="_blank">Mikael Holz</a></p>
                    </li>
                    <li class="gallery__item">
                        <a href="{{ meifteImg }}" target="_blank" class="gallery__img-container"><img class="gallery__img" src="{{ meifteThumbnail }}" alt="photo"></a>
                        <p class="gallery__comment"><span class="text text_type_body-reg">Meifte</span></p>
                        <p><span class="text text_type_body-reg">by </span><a class="text text_type_body-link" href="https://www.treharneart.com/" target="_blank">Lauren Treharne</a></p>
                    </li>
                    <li class="gallery__item">
                        <a href="{{ treeImg }}" target="_blank" class="gallery__img-container"><img class="gallery__img" src="{{ treeThumbnail }}" alt="photo"></a>
                        <p class="gallery__comment"><span class="text text_type_body-reg">Part of the tree</span></p>
                        <p><span class="text text_type_body-reg">by </span><span class="text text_type_body-reg">doctordisco819</span></p>
                    </li>
                    <li class="gallery__item">
                        <a href="{{ thoraldImg }}" target="_blank" class="gallery__img-container"><img class="gallery__img" src="{{ thoraldThumbnail }}" alt="photo"></a>
                        <p class="gallery__comment"><span class="text text_type_body-reg">Thorald</span></p>
                        <p><span class="text text_type_body-reg">by </span><a class="text text_type_body-link" href="https://www.instagram.com/lure_wangnewone/" target="_blank">WangNewOne</a></p>
                    </li>
                </ul>
                <p class="photos__description"><span class="text text_type_body-reg">If you have any pictures you think would fit here please email them to </span><a class="text text_type_body-link" href="mailto: thedescendants@mail.com">thedescendants@mail.com</a></p>
            </div>
        </div>
        <img class="extras-section__block extras-section__centered-img" src="{{ sisyphusImg }}" alt="photo">
<!--        > my-photos}}-->
    </article>
</section>