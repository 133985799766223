import FlowGallery from './libs/flow-gallery/flow-gallery.min';
import Plyr from 'plyr';

function initGallery() {
  hideGallery();

  const result = document.getElementsByClassName('gallery-player');
  const promises = [];
  const timeout = 60000;

  Array.from(result).map((element) => {
    const parent = element.parentElement;

    const player = new Plyr(element, {
      volume: 0,
      autoplay: true,
      controls: [],
      fullscreen: {
        enabled: false,
      },
      muted: true,
      loop: {
        active: true,
      },
      clickToPlay: false
    });

    player.on('ready', () => player.volume = 0);

    const promise = new Promise((resolve, reject) => {
      setTimeout(reject, timeout);
      element.addEventListener("loadedmetadata", function (e) {
        const width = this.videoWidth;
        const height = this.videoHeight;

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const img = new Image();
        img.src = canvas.toDataURL();
        img.classList.add('collection-section__fake-image');
        parent.prepend(img);

        resolve();
      }, false);
    });
    promises.push(promise);
  });

  const images = Array.from(document.getElementsByClassName('collection-section__img'))
  images.forEach((image) => {
    if (image.complete) return;
    const promise = new Promise((resolve, reject) => {
      setTimeout(reject, timeout);
      image.onload = function () {
        resolve();
      }
    });
    promises.push(promise);
  });

  Promise
    .all(promises)
    .then(initFlowGallery);
}

function initFlowGallery() {
  const gallery = new FlowGallery({
    maxHeight: 400,
    align: 'center',
    enlarge: false,
    gapWidth: getGap(),
  });

  showGallery();
}

function hideGallery() {
  const result = document.getElementsByClassName('flow-gallery');
  Array.from(result).forEach((item) => {
    item.classList.remove('collection-section__gallery_visible');
  });
}

function showGallery() {
  const result = document.getElementsByClassName('flow-gallery');
  Array.from(result).forEach((item) => {
    item.classList.add('collection-section__gallery_visible');
  });
}

function getGap() {
  return window.innerWidth <= 768 ? 12 : 20;
}

export default initGallery;