<section class="container about-section" id="about-container">
    <article class="about-section__block about-section__video">
        <div class="windows-player-wrapper">
            <div class="windows-player-wrapper__content">
                <div class="windows-player-wrapper__loader">
                    <img class="windows-player-wrapper__loader-img" src="{{mediaLoaderImg}}" alt="media loader">
                    <p class="text text_type_pixeled">Connecting to media...</p>
                </div>
                <video class="windows-player-wrapper__video" id="about-page-player" playsinline>
                    <source src="{{ aboutVideo }}" type="video/mp4"/>
                </video>
            </div>
        </div>
    </article>
    <div class="about-section__block">
        <h1 class="about-section__title"><span class="text text_type_h1 text_accented">~The Descendants~</span></h1>
        <p class="text text_type_body-reg">Very little was known about the Geldmaer family until a comprehensive tree was discovered in a collection of discarded papers. This document exposed a genealogy that stretched back generations, detailing hundreds of individuals and the relationships throughout their lifetimes. That tree is presented to you in its entirety on this webpage, and I hope you will find it as interesting as I have.</p>
    </div>
    <div class="box about-section__block about-section__contacts">
        <div class="box__content contacts">
            <p class="contacts__p"><span class="text text_type_body-reg">Follow the project on Twitter </span><a class="text text_type_body-link" href="https://twitter.com/GeldmaerFamily" target="_blank">@GeldmaerFamily</a></p>
            <p class="contacts__p"><span class="text text_type_body-reg">Join the </span><a class="text text_type_body-link" href="http://discord.gg/RdG6g9uVUW" target="_blank">Discord community</a></p>
<!--            <p class="contacts__p"><span class="text text_type_body-reg">Latest mints Twitter bot </span><a class="text text_type_body-link" href="https://twitter.com/GeldmaerBot" target="_blank">@GeldmaerBot</a></p>-->
        </div>
    </div>
    <div class="about-section__block">
        <p class="about-section__p"><span class="text text_type_body-reg">The Descendants consist of 750 distinctive family members, displayed within the context of their family tree. Upon minting a Descendant, an animated portrait accompanied by music and biographical information is revealed. As you dig deeper into the Geldmaer family, you will learn about their lineage, influence their history, and bring their stories to life.</span></p>
        <p class="about-section__p">
            <span class="text text_type_body-reg">The first fifty Descendants cost 5 tez each. This price increases incrementally by 5 tez for each fifty minted. Video portraits are HD 9:16 mp4s. Stills are JPGs. The Descendants can be traded on </span><!--
         --><a class="text text_type_body-link" href="https://objkt.com" target="_blank">objkt.com</a><!--
         --><span class="text text_type_body-reg">.</span>
        </p>
    </div>
    <img class="about-section__block" src="{{coatOfArmsTransparentImg}}" alt="Coat of arms">
    <div class="about-section__block">
        <p class="about-section__p about-section__author">
            <span class="text text_type_body-reg">The Descendants is a project built on </span><!--
         --><a class="text text_type_body-link" href="https://tezos.com/" target="_blank">Tezos</a><!--
         --><span class="text text_type_body-reg">, by </span><!--
         --><a class="text text_type_body-link" href="https://twitter.com/brianbrian_____" target=_blank>@Brian Tessler</a><!--
         --><span class="text text_type_body-reg"> and </span><!--
         --><a class="text text_type_body-link" href="https://twitter.com/Kim__Laughton" target=_blank>@Kim Laughton</a><!--
         --><span class="text text_type_body-reg">.</span>
        </p>
        <p class="about-section__contacts-by"><span class="text text_type_body-reg">Contracts by </span>
            <a class="text text_type_body-link" href="https://twitter.com/landlinesart1" target="_blank">@landlinesart</a><!--
        --><span class="text text_type_body-reg">, DApp/Website by </span><!--
        --><a class="text text_type_body-link" href="https://twitter.com/chainsawnft" target="_blank">@chainsawnft</a><!--
        --><span class="text text_type_body-reg"> and </span><!--
        --><a class="text text_type_body-link" href="https://twitter.com/ir8prim8" target="_blank">@ir8prim8</a>
        </p>
    </div>
    <div class="about-section__block">
        <a class="about-section__chainsaw-link" href="https://www.chainsaw.fun/" target="_blank">
            <img src="{{ chainsowImg }}" alt="chainsaw">
        </a>
        <p class="about-section__p">
            <span class="text text_type_body-italic">~Chain/saw - The future of web3~</span>
        </p>
    </div>
    <div class="about-section__block">
        <p class="about-section__p">
            <span class="text text_type_body-reg">Calligraphy by </span><!--
         --><a class="text text_type_body-link" href="https://www.tiktok.com/@caliletters" target="_blank">caliletters</a><!--
         --><span class="text text_type_body-reg">, coat of arms by </span><!--
         --><a class="text text_type_body-link" href="https://www.instagram.com/mikaelholz/" target="_blank">Mikael Holz</a>
        </p>
        <p><span class="text text_type_body-reg">Illustrations in introduction video by </span><a class="text text_type_body-link" href="https://www.treharneart.com/" target="_blank">Lauren Treharne</a><span class="text text_type_body-reg"> and </span><a class="text text_type_body-link" href="https://www.instagram.com/lure_wangnewone/" target="_blank">WangNewOne</a></p>
        <p>
            <span class="text text_type_body-reg">Voice of Henry performed by </span><!--
         --><a class="text text_type_body-link" href="https://sites.gravyforthebrain.com/website/peter-walters/" target="_blank">Peter Walters</a>
        </p>
        <p class="about-section__p"><span class="text text_type_body-reg">Biographies for each Descendant were created with GPT2 text generator.</span></p>
        <p class="about-section__p">
            <span class="text text_type_body-reg">Music/MIDI for each video generated by </span><!--
         --><a class="text text_type_body-link" href="http://computoser.com/" target=_blank>computoser.com</a><!--
         --><span class="text text_type_body-reg">, with custom orchestration by Brian Tessler. Licensed under a Creative Commons Attribution-ShareAlike 4.0 International License.</span>
        </p>
    </div>
    <img class="about-section__block" src={{pomegranateImg}} alt="Pomegranate">
    <p class="about-section__last-prase about-section__block"><span class="text text_type_body-reg">Hopefully, if you're looking for connections, you might be lucky and find relevant information.</span></p>
</section>