function mapAddonId(id) {
    const str = String(id);

    return {
        descendantId: Number(str.slice(1, 5)),
        addonId: Number(str.slice(5, 9)),
        iteration: Number(str.slice(9)),
    };
}

export default mapAddonId;