const images = new Set();
let lastPromise = null;
let handleUpdate = () => {};

function addImage(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) return;

    const size = images.size;
    images.add(url);

    if (size === 0) {
        handleUpdate();
    }

    const promise = new Promise((resolve => {
        img.onload = function () {
            images.delete(url);
            resolve();
        }
    }));

    const last = Promise.all([lastPromise, promise]);
    last.then(() => {
        if (last === lastPromise) {
            handleUpdate();
        }
    });

    lastPromise = last;
}

function getIsLoading() {
    return images.size > 0;
}

function setOnIsLoadingUpdate(handleStateUpdate) {
    handleUpdate = handleStateUpdate;
}

export default {
    addImage,
    getIsLoading,
    setOnIsLoadingUpdate,
};