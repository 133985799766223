<section class="portrait-section container">
    {{#unless isMinted }}
        {{> portrait-preview}}
    {{else}}
        {{> portrait}}
    {{/unless}}
    <div class="portrait-section__description">
        {{#unless isMinted }}
            {{> empty-description}}
            <article class="box minting-form portrait-section__minting-form">
                <div class="box__content minting-form__content">
                    <h2 class="text text_type_h2">Mint {{ name }} for {{ @root.price }} tez</h2>
                    <div class="minting-form__button-container">
                        <button class="button" onclick="mint({{ id }})">Mint Now</button>
                    </div>
                    <p class="text text_type_body-reg">Descendants start at 5 tez and the price increases by 5 tez for each 50 sold.</p>
                </div>
            </article>
        {{else}}
            {{> filled-description}}
        {{/unless}}
    </div>
</section>
{{# if isMinted }}
    {{#if isMine }}
        {{#if isMaxHobbies}}
        {{else}}
            {{#if displayHobbies }}
                {{> hobbies-section }}
            {{/if}}
        {{/if}}
        {{#if isPaintingMintingAvailable }}
            {{> minting-painting}}
        {{/if}}
    {{/if}}
    {{#each paintingsPayloads}}
        {{> painting-section class='additional-section'}}
    {{/each}}
{{/if}}