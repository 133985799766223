function initHobbiesLimit(alreadyMinted) {
    Array
        .from(document.getElementsByClassName('addHobbies'))
        .forEach((checkbox) => checkbox.addEventListener('change', () => update(alreadyMinted)));
}

function update(alreadyMinted) {
    const limit = 3 - alreadyMinted;

    const amount = Array
        .from(document.getElementsByClassName('addHobbies'))
        .filter((checkbox) => checkbox.checked)
        .length;

    if (amount + 1 > limit) {
        Array
            .from(document.getElementsByClassName('addHobbies'))
            .filter((checkbox) => !checkbox.checked)
            .forEach((checkbox) => checkbox.disabled = true);
    } else {
        Array
            .from(document.getElementsByClassName('addHobbies'))
            .filter((checkbox) => !checkbox.checked)
            .forEach((checkbox) => checkbox.disabled = false);
    }

    Array
        .from(document.getElementsByClassName('hobbies-counter'))
        .forEach((span) => span.innerHTML = String(limit - amount));
}

export default initHobbiesLimit;