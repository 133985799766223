async function fetchWithCache(fetch) {
    let cache = null;

    if (cache) return cache;

    try {
        cache = await fetch();
        return cache;
    } catch(error) {
        console.log(error);
    }
}

function getBigmapId(bigmaps, name) {
    const bigmap = bigmaps.find((b) => b.path === name);
    return bigmap.ptr;
}

async function fetchFA2Bigmaps() {
    const result = await fetch(`${window.getApiUrl()}v1/bigmaps?contract=${window.contracts.FA2}`);
    const bigmaps = await result.json();
    return {
        ledger: getBigmapId(bigmaps, 'ledger'),
        token_metadata: getBigmapId(bigmaps, 'token_metadata'),
    };
}

async function fetchFA2AddonsBigmaps() {
    const result = await fetch(`${window.getApiUrl()}v1/bigmaps?contract=${window.contracts.FA2ADDON}`);
    const bigmaps = await result.json();
    return {
        token_metadata: getBigmapId(bigmaps, 'token_metadata'),
    };
}

async function fetchMINTERBigmaps() {
    const result = await fetch(`${window.getApiUrl()}v1/bigmaps?contract=${window.contracts.MINTER}`);
    const bigmaps = await result.json();
    return {
        addons: getBigmapId(bigmaps, 'addons'),
    };
}

export async function getFA2Bigmaps() {
    return await fetchWithCache(fetchFA2Bigmaps);
}

export async function getFA2AddonsBigmaps() {
    return await fetchWithCache(fetchFA2AddonsBigmaps);
}

export async function getMinterBigmaps() {
    return await fetchWithCache(fetchMINTERBigmaps);
}