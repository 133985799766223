{{#if childrenNames}}
    <div class="description__block">
        <p class="description__property">
            <span class="text text_type_body-bold text_accented description__property-name">Children:</span>
        </p>
        <p>
            {{#each children}}
                <a class="text text_type_body-italic-link" href="#descendant/{{this}}">{{ lookup ../childrenNames this }}</a><!--
                 -->{{#unless @last}}<span class="text text_type_body-reg">, </span>{{/unless}}
            {{/each}}
    </div>
{{/if}}