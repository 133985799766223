<section class="minting-painting-section additional-section container">
    <div class="box minting-painting-section__form">
        <div class="box__content minting-painting-form">
            <h2 class="minting-painting-form__title"><span class="text text_type_h2 text_accented">~ PAINTINGS NOW AVAILABLE ~</span></h2>
            <p class="minting-painting-form__description"><span class="text text_type_body-reg">{{ firstName }} is a painter and can mint up to 3 paintings for 5 tez each</span><span class="text text_type_body-bold"> ({{get this "availablePaintingsAmount"}} remaining)</span></p>
            <div class="minting-painting-form__button-container">
                <button class="button" onclick="mintPainting({{id}})">Mint a painting</button>
            </div>
            <div>
                <p class="text text_type_body-italic">Paintings are NFTs and can be traded independently of {{ firstName }}.</p>
            </div>
        </div>
    </div>
</section>