const sound = {
    isEnabled: true,
    enableSound(player) {
        player.volume = 1;
        player.muted = false;
        this.isEnabled = true;
    },
    disableSound(player) {
        player.volume = 0;
        player.muted = true;
        this.isEnabled = false;
    },
};

export default sound;