const notStartedClassName = 'plyr_not-started';

function initMp4Controls(player) {
    const stopControl = player.elements.controls.querySelector('[data-plyr="stop"]')
    stopControl.addEventListener('click', () => stopWatching(player));

    player.on('play', () => startWatching(player));
    player.on('ended', () => onEnd(player));
}

function startWatching(player) {
    player.elements.controls.classList.remove(notStartedClassName);
}

function stopWatching(player) {
    player.elements.controls.classList.add(notStartedClassName);
    player.stop();
}

function onEnd(player) {
    player.elements.controls.classList.add(notStartedClassName);
}

export default initMp4Controls;