function getOnImgLoadedPromise(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) return Promise.resolve();

    return new Promise((resolve => {
        img.onload = function () {
            console.log('loaded', url)
            resolve();
        }
    }));
}

export default getOnImgLoadedPromise;