<section class="container entry">
    <div class="header__logo-link entry__logo"><img src="{{ entryLogo }}" alt="logo"></div>
    <article class="portrait entry__portrait">
        <div class="windows-player-wrapper">
            <div class="windows-player-wrapper__content">
                <div class="windows-player-wrapper__loader">
                    <img class="windows-player-wrapper__loader-img" src="{{mediaLoaderImg}}" alt="media loader">
                    <p class="text text_type_pixeled">Connecting to media...</p>
                </div>
                <video class="windows-player-wrapper__video" id="entry-player" playsinline>
                    <source src="{{ entryVideo }}" type="video/mp4"/>
                </video>
            </div>
        </div>
    </article>
    <div class="entry__enter-block">
        <a href="#" onclick="enter(); return false" class="text text_type_h2 text_accented text_underlined">CLICK HERE</a>
        <p class="text text_type_h2 text_accented">to enter the website</p>
    </div>
</section>