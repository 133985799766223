import routie from 'routie';
import Handlebars  from 'handlebars';

import coatOfArmsImg from '../images/Coat of arms.png';
import coatOfArmsTransparentImg from '../images/Coat of arms low.png';
import pomegranateImg from '../images/Pomegranate-2.png';
import soundImg from '../images/Sound.png';
import soundMutedImg from '../images/Sound muted.png';
import meifteImg from '../images/Meifte.jpg';
import treeImg from '../images/Tree.jpg';
import thoraldImg from '../images/Thorald.jpg';
import sisyphusImg from '../images/SISYPHUS.gif';
import hourglassImg from '../images/hourglass.gif';
import errorImg from '../images/error.gif';
import layer1Img from '../images/Layer 1.png';
import layer2Img from '../images/Layer 2.png';
import layer3Img from '../images/Layer 3.png';
import layer4Img from '../images/Layer 4.png';
import layer5Img from '../images/Layer 5.png';
import layer6Img from '../images/Layer 6.png';
import coatOfArmsThumbnail from '../images/thumbnails/Coat of arms.png';
import thoraldThumbnail from '../images/thumbnails/Thorald.png';
import meifteThumbnail from '../images/thumbnails/Meifte.png';
import treeThumbnail from '../images/thumbnails/Tree.png';
import entryLogo from '../images/Descendants-text-wide 1.png';
import mediaLoaderImg from '../images/media connecting.png';
import chainsowImg from '../images/chainsaw.gif';

import initPlayer from './initPlayer';
import initAboutPagePlayer from './initAboutPagePlayer';
import initGallery from './initGallery';
import initHobbiesLimit from './initHobbiesLimit';
import initMinimapUpdating from './initMinimapUpdating';
import initEntryPlayer from './initEntryPlayer';
import store from './store';
import pages, {Page} from './pages';
import {getTooltipText} from './map';
import constants from './constants';
import getOnImgLoadedPromise from './getOnImgLoadedPromise';
import getOnVideoReadyPromise from './getOnVideoReadyPromise';

import descendants from './tree_data.json';
import {
    updateActiveAccount,
    loadContractStorage,
    loadMintedTokens,
    getPrice,
    signedPayload,
    getSenderId,
    loadAddons,
    loadOwnedTokens,
    loadOwnedAddons,
    loadAccount,
    getAddress,
    loadOwnedPaintings, loadPaintingById
} from './dapp.js';
import { drawMap } from './map.js';
import { parseDescendants, indexDescendant } from './descendant.js';
import { getAllTokenMetadataHashes } from './dapp.js';


import aboutTemplate from '../_hbs/about.hbs';
import collectionTemplate from '../_hbs/collection.hbs';
import descendantTemplate from '../_hbs/descendant.hbs';
import extrasTemplate from '../_hbs/extras.hbs';
import entryTemplate from '../_hbs/entry.hbs';
import portraitLoaderTemplate from '../_hbs/portrait-loader.hbs';
import transactionErrorTemplate from '../_hbs/transaction-error.hbs';
import emptyDescriptionPartial from '../_hbs/empty-description.hbs';
import childrenBlockPartial from '../_hbs/children-block.hbs';
import portraitPreviewPartial from '../_hbs/portrait-preview.hbs';
import filledDescriptionPartial from '../_hbs/filled-description.hbs';
import portraitPartial from '../_hbs/portrait.hbs';
import paintingSectionPartial from '../_hbs/painting-section.hbs';
import hobbiesSectionPartial from '../_hbs/hobbies-section.hbs';
import mintingPaintingPartial from '../_hbs/minting-painting.hbs';
import familyBlockPartial from '../_hbs/family-block.hbs';
import myPhotosPartial from '../_hbs/my-photos.hbs';
import treeLoading from "./treeLoading";
import mapAddonId from "./mapAddonId";
import createAddonId from "./createAddonId";
import createS3PaintingUrl from "./createS3PaintingUrl";

const aboutVideo = 'https://descendants-cdn.chainsaw.fun/Videos/Henry+webcam+intro.mp4';
const entryVideo = 'https://descendants-cdn.chainsaw.fun/Videos/Intro+embedded.mp4';
const extrasVideo = 'https://descendants-cdn.chainsaw.fun/Videos/The+Descendants+Intro.mp4';
const extrasSound = 'https://descendants-cdn.chainsaw.fun/Sound/Descendants+Theme+1+-+baroque+arrangement.wav';

Handlebars.registerPartial('empty-description', emptyDescriptionPartial);
Handlebars.registerPartial('children-block', childrenBlockPartial);
Handlebars.registerPartial('portrait-preview', portraitPreviewPartial);
Handlebars.registerPartial('filled-description', filledDescriptionPartial);
Handlebars.registerPartial('portrait', portraitPartial);
Handlebars.registerPartial('painting-section', paintingSectionPartial);
Handlebars.registerPartial('hobbies-section', hobbiesSectionPartial);
Handlebars.registerPartial('minting-painting', mintingPaintingPartial);
Handlebars.registerPartial('family-block', familyBlockPartial);
Handlebars.registerPartial('my-photos', myPhotosPartial);

Handlebars.registerHelper('getTooltipText', function (collectionItem) {
    if (collectionItem.isPainting) {
        return collectionItem.name;
    } else {
        return getTooltipText(collectionItem);
    }
})

Handlebars.registerHelper('get', function(model, attributeName)
{
    return model[attributeName];
});

let addons = [];
let descendantsCollection = [];
let paintingsCollection = [];
const beingUpdatedCharacters = [];

function showLoader(id) {
    beingUpdatedCharacters.push(id);
    loadSite();
}

function hideLoader(id) {
    const index = beingUpdatedCharacters.indexOf(id);
    if (index === -1) return;
    beingUpdatedCharacters.splice(index, 1);
    loadSite();
}

const setupNavbar = () => {
     // If the user clicks their wallet address, the sync will be reset
  document.getElementById('activeAccount').addEventListener('click', logout);
}

// only load the one metadata and redraw view.
const tryToLoad = (tokenId, waitMilliseconds) => {
    setTimeout(() => {
        waitModal.classList.remove('is-active');
        const _data = data[tokenId-1]
        const d = _data.toObject();
        console.log(`reindex ${tokenId}`)
        indexDescendant(d, d['URL'], window.collection).then((descendant) => {
            window.data[tokenId] = descendant;
            console.log('show', descendant)
            showCharacter(tokenId);
        })
        .catch(e => { console.error(e) });
    }, waitMilliseconds);
}

window.mint = (id) => {
    window.taquito
        .mint(contracts.MINTER, id, getPrice(), () => showLoader(id))
        .then((response) => {
            setTimeout(async () => {
                const newCollection = await loadOwnedTokens();
                hideLoader(id);
                if (!newCollection.includes(String(id))) {
                    window.location.hash = '#error';
                }
            }, 1000);
        })
        .catch(e => {
            console.error(e);
            hideLoader(id);
        });
}

window.mintAddon = (descendantId, addonsIds) => {
    const toBuy = Array.from(window.document.getElementsByClassName('addHobbies'))
        .filter(i => { return i.checked } )
        .map(i => {
            return { addonId: i.name, iterationId: 1, cost: addons[i.name].price }
        });
    if (toBuy && toBuy.length > 3) {
        alert('Only three hobbies may be added to a descendant.');
        return;
    }
    if (toBuy && toBuy.length > 0) {
        window.taquito
            .mintAddons(contracts.MINTER, descendantId, toBuy, () => showLoader(descendantId))
            .then(() => {
                setTimeout(() => hideLoader(descendantId), 1000);
            })
            .catch(e => {
                console.error(e);
                hideLoader(descendantId);
            });
    }
    
}

window.mintPainting = (descendantId) => {
    const addon = Object.values(addons).find((a) => String(a.id) === constants.PAINTING_ADDON_ID);

    const characterData = data[Number(descendantId) - 1]
    const iterationId = 1 + characterData.paintings.length;

    window.taquito.mintAddon(contracts.MINTER, addon.price, descendantId, addon.id, iterationId, () => showLoader(descendantId))
        .then(() => {
            setTimeout(() => hideLoader(descendantId), 1000);
        })
        .catch((e) => {
            console.error(e);
            hideLoader(descendantId);
        });
}

function hideTree() {
    Array
        .from(document.getElementsByClassName('page'))
        .forEach((page) => page.classList.remove('page_tree'));

    document.getElementById('map-container').classList.add('is-hidden');
    Array
        .from(document.getElementsByClassName('tree-controls'))
        .forEach((page) => page.classList.add('is-hidden'));

    const loader = getTreeLoader();
    loader.classList.add('is-hidden');

    treeLoading.setOnIsLoadingUpdate(() => {});
}

window.showCharacter = async (id) => {
    pages.setPage(Page.CHARACTER)
    hideTree();

    const char = document.getElementById('character');
    const characterData = data[Number(id) - 1]

    char.innerHTML = portraitLoaderTemplate({
        hourglassImg,
    });
    char.classList.remove('is-hidden')

    /**
     * Start of async operations
     */

    const characterAddons = await loadOwnedAddons(addons, id, characterData.firstName);

    if (characterData.account) {
        characterData.accountAlias = await loadAccount(characterData.account);
        characterData.accountDomain = await window.taquito.fetchTezosDomainFromAddress(characterData.account);
        characterData.accountFinalName = characterData.accountDomain !== characterData.account
            ? characterData.accountDomain
            : characterData.accountAlias;
    }

    characterData.injected = {
        soundImg,
        soundMutedImg,
        portraitBlurredImgUrl: `${characterData.url}/Blurred/${characterData.id}.jpg`,
        portraitImgUrl: `${characterData.url}/Stills/${characterData.fileName}.jpg`,
        portraitSmallVideoUrl: `${characterData.url}/Videos/${characterData.fileName}.crf26.mp4`,
        portraitBigVideoUrl: `${characterData.url}/Videos/${characterData.fileName}.mp4`,
    };

    if (characterData.isMinted()) {
        await getOnVideoReadyPromise(characterData.injected.portraitSmallVideoUrl);
    } else {
        await getOnImgLoadedPromise(characterData.injected.portraitBlurredImgUrl);
    }

    characterData['price'] = getPrice();
    characterData.setFamily(window.fam);
    characterData.setAddons(addons, characterAddons);

    const paintingsIds = characterData.paintings.map((p) => createAddonId(characterData.id, p.id, p.iteration));
    const paintings = await Promise.all(paintingsIds.map((id) => loadPaintingById(id)));
    const addressesNames = await Promise.all(paintings.map((p) => fetchAddressName(p.account)));

    /**
     * End of async operations
     * Another page could've been shown
     */
    if (pages.currentPage !== Page.CHARACTER) return;

    characterData.paintingsTokens = paintings;
    characterData.minterContract = contracts.MINTER;
    characterData.objktLink = `https://objkt.com/asset/geldmaer-descendants/${characterData.id}`;
    console.log('character data', characterData);

    characterData.paintingsPayloads = paintings.map((p, key) => ({
        descendantId: characterData.id,
        descendantName: characterData.name,
        paintingId: p.id,
        paintingOwnerName: addressesNames[key],
        paintingOwnerAccount: p.account,
        paintingName: p.metadata.name,
        paintingUrl: createS3PaintingUrl(p.id),
        minterContract: contracts.MINTER,
    }));

    if (beingUpdatedCharacters.includes(characterData.id)) {
        // loader is already visible
    } else {
        char.innerHTML = descendantTemplate(characterData, { allowedProtoMethods: {
            children: true,
            isMinted: true,
            isUnindexed: true,
        }});

        initPlayer();
        initHobbiesLimit(characterData.hobbies.length);
    }
}

window.showTree = () => {
    pages.setPage(Page.TREE);
    treeLoading.setOnIsLoadingUpdate(showTree);

    const loader = getTreeLoader();

    if (treeLoading.getIsLoading()) {
        Array
            .from(document.getElementsByClassName('page'))
            .forEach((page) => page.classList.remove('page_tree'));

        Array
            .from(document.getElementsByClassName('tree-controls'))
            .forEach((page) => page.classList.add('is-hidden'));

        hideTree();
        treeLoading.setOnIsLoadingUpdate(showTree);

        character.classList.add('is-hidden');
        character.innerHTML = '';
        loader.classList.remove('is-hidden');
    } else {
        Array
            .from(document.getElementsByClassName('page'))
            .forEach((page) => page.classList.add('page_tree'));

        Array
            .from(document.getElementsByClassName('tree-controls'))
            .forEach((page) => page.classList.remove('is-hidden'));

        loader.classList.add('is-hidden');

        // this is only needed after window is resized. Will cause leaflet to redraw with the new dimensions.
        window.dispatchEvent(new Event('resize'));

        character.classList.add('is-hidden');
        character.innerHTML = '';
        document.getElementById('map-container').classList.remove('is-hidden');
    }
}

window.showModal = function(message) {
    waitModal.classList.add('is-active')
    if (typeof message == 'undefined') {
        message = 'Please wait, the history of the Geldmaers is being written...';
    }
    waitModal.children[1].innerHTML = `<p>${message}</p>`;
}

window.opModal = function(network, op) {
    const tzktURL = `https://${network}.tzkt.io/${op.opHash}`
    showModal(`<a href="javascript: waitModal.classList.remove('is-active')" class="closeModal">x</a>` +
        `Please wait for confirmation. Your hash is: ` +
        `<a href="${tzktURL}" class="has-text-green" target=_blank>${op.opHash}</a>`)
}

window.enter = function () {
    store.setIsVisited(true);
    window.history.pushState('main', 'The Descendants', '/#tree');
    updateNavigation();
    loadSite();
}

function showHeader() {
    Array
        .from(document.getElementsByClassName('header'))
        .forEach((page) => page.classList.remove('is-hidden'));

    const images = Array.from(document.querySelectorAll('.header img'));

    const promises = images.map((image) => {
        if  (image.complete) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            setTimeout(reject, timeout);
            image.onload = function () {
                resolve();
            }
        });
    });

    return Promise.all(promises);
}

function hideHeader() {
    Array
        .from(document.getElementsByClassName('header'))
        .forEach((page) => page.classList.add('is-hidden'));
}

const showAbout = () => {
    if (pages.currentPage === Page.ABOUT) return;

    pages.setPage(Page.ABOUT);

    Array
        .from(document.getElementsByClassName('page'))
        .forEach((page) => page.classList.remove('page_tree'));

    hideTree();
    showHeader();

    const char = document.getElementById('character');
    char.classList.remove('is-hidden')
    char.innerHTML = aboutTemplate({
        coatOfArmsTransparentImg,
        pomegranateImg,
        aboutVideo,
        soundImg,
        soundMutedImg,
        mediaLoaderImg,
        chainsowImg,
    });
    initAboutPagePlayer('about-page-player');
}

const showEntry = () => {
    pages.setPage(Page.ENTRY);

    Array
        .from(document.getElementsByClassName('page'))
        .forEach((page) => page.classList.remove('page_tree'));

    hideTree();
    hideHeader();

    const char = document.getElementById('character');
    char.classList.remove('is-hidden')
    char.innerHTML = entryTemplate({
        entryLogo,
        entryVideo,
        soundImg,
        soundMutedImg,
        aboutVideo,
        mediaLoaderImg,
    });
    initEntryPlayer();
}

const showCollection = () => {
    pages.setPage(Page.COLLECTION);

    Array
        .from(document.getElementsByClassName('page'))
        .forEach((page) => page.classList.remove('page_tree'));

    hideTree();
    showHeader();

    const myCollection = [
        ...descendantsCollection.map(c => fam.get(c) ),
        ...paintingsCollection.map((p) => ({
            paintingId: p.id,
            isPainting: true,
            name: p.metadata.name,
            paintingUrl: createS3PaintingUrl(p.id),
        })),
    ];

    const char = document.getElementById('character');
    char.classList.remove('is-hidden')
    const opts = { allowedProtoMethods: { children: true, thumbnail: true, isMinted: true } };
    char.innerHTML = collectionTemplate({
        collection: myCollection,
        title: getCollectionTitle(),
    }, opts);

    initGallery();
}

function getCollectionTitle() {
    if (!getAddress()) return 'Sync Tezos wallet to view collection';
    if (!descendantsCollection.length && !paintingsCollection.length) return 'Research the Geldmaer family to begin your collection';
    return '~COLLECTION~';
}

const showPainting = async (id) => {
    pages.setPage(Page.PAINTING);

    Array
        .from(document.getElementsByClassName('page'))
        .forEach((page) => page.classList.remove('page_tree'));

    hideTree();
    showHeader();

    /**
     * Start of async operations
     */

    const token = await loadPaintingById(id);
    const accountFinalName = await fetchAddressName(token.account);

    /**
     * End of async operations
     * Another page could've been shown
     */
    if (pages.currentPage !== Page.PAINTING) return;

    const idData = mapAddonId(id);
    const characterData = data[Number(idData.descendantId) - 1]

    const char = document.getElementById('character');
    char.classList.remove('is-hidden')
    char.innerHTML = paintingSectionPartial({
        descendantId: characterData.id,
        descendantName: characterData.name,
        paintingId: id,
        paintingName: token.metadata.name,
        paintingOwnerName: accountFinalName,
        paintingOwnerAccount: token.account,
        paintingUrl: createS3PaintingUrl(id),
        minterContract: contracts.MINTER,
    });
}

async function fetchAddressName(address) {
    const alias = await loadAccount(address);
    const domain = await window.taquito.fetchTezosDomainFromAddress(address);
    return domain !== address
        ? domain
        : alias;
}

const showExtras = () => {
    if (pages.currentPage === Page.EXTRA) return;

    pages.setPage(Page.EXTRA);

    Array
        .from(document.getElementsByClassName('page'))
        .forEach((page) => page.classList.remove('page_tree'));

    hideTree();
    showHeader();

    const char = document.getElementById('character');
    char.classList.remove('is-hidden')
    char.innerHTML = extrasTemplate({
        coatOfArmsImg,
        meifteImg,
        treeImg,
        thoraldImg,
        sisyphusImg,
        layer1Img,
        layer2Img,
        layer3Img,
        layer4Img,
        layer5Img,
        layer6Img,
        extrasVideo,
        extrasSound,
        coatOfArmsThumbnail,
        thoraldThumbnail,
        meifteThumbnail,
        treeThumbnail,
    });
}

const showError = () => {
    if (pages.currentPage === Page.ERROR) return;

    pages.setPage(Page.ERROR);

    Array
        .from(document.getElementsByClassName('page'))
        .forEach((page) => page.classList.remove('page_tree'));

    hideTree();
    showHeader();

    const char = document.getElementById('character');
    char.classList.remove('is-hidden')
    char.innerHTML = transactionErrorTemplate({
        errorImg,
    });
}

window.routie = routie;
const updateNavigation = () => {
    if (store.getIsVisited() || authenticated()) {
        store.setIsVisited(true);

        Array
            .from(document.getElementsByClassName('header'))
            .forEach((page) => page.classList.remove('is-hidden'));

        window.routie.removeAll();
        window.routie({
            'descendant/:id': function (id) {
                window.showCharacter(id);
            },
            'painting/:id': function (id) {
                showPainting(id);
            },
            'about': () => { showAbout() },
            'extras': () => { showExtras() },
            'collection': () => { showCollection() },
            'tree': showTree,
            'entry': showEntry,
            'error': showError,
            '': showTree,
        })
    } else {
        window.routie.removeAll();
        window.routie({
            '*': showEntry,
        });
    }
}

window.updateNavigation = updateNavigation;

const loadSite = () => {
    loadMintedTokens().then(async (tokens) => {
        await showHeader();

        addons = await loadAddons();
        descendantsCollection = await loadOwnedTokens();
        paintingsCollection = await loadOwnedPaintings();

        await loadContractStorage();

        showModal();
        const tokenMetadataHashes = await getAllTokenMetadataHashes();        
        window.data = await Promise.all(parseDescendants(descendants, constants.S3_URL, tokens, descendantsCollection, tokenMetadataHashes));

        const scaleFactor = 3.0;
        var mapSize = [960 * scaleFactor, 21000 * scaleFactor];

        const minimap = document.getElementById('minimap');

        minimap.addEventListener('change', (ev) => {
            console.log(ev.target.value);
            window.tree.panTo([ mapSize[1] * (Number(ev.target.value) * 0.01), mapSize[0] * 0.5 ])
        });

        const handleWheel = function(e) {
            if(e.ctrlKey || e.metaKey)
                e.preventDefault();
        };
        window.addEventListener("wheel", handleWheel, { passive: false });

        window.tree = drawMap(mapSize, window.data);
        updateNavigation();
        initMinimapUpdating(tree, minimap, mapSize);
        waitModal.classList.remove('is-active');
    })
}

const loadUI = () => {
    if (store.getIsVisited()) {
        loadSite();
    } else {
        updateNavigation();
    }
}
window.loadUI = loadUI;

const authenticated = () => {
    const senderId = getSenderId();
    return signedPayload && signedPayload.senderId == senderId;
}
window.authenticated = authenticated;

window.domLoadedEvent = () => {
    initTreeLoader();

    updateActiveAccount()

    setupNavbar();

    const syncButton = document.getElementById('sync-1');
    syncButton.addEventListener('click', () => {
        window.taquito.requestPermission(() => {
            console.log('Wallet connected')
            updateActiveAccount()
        });
    });
};

function initTreeLoader() {
    const container = getTreeLoader();
    container.innerHTML = portraitLoaderTemplate({
        hourglassImg,
    });
}

function getTreeLoader() {
    return document.getElementById('tree-loader');
}

document.addEventListener('DOMContentLoaded', window.domLoadedEvent);