<article class="description">
    <div class="description__header">
        <h2 class="description__title"><span class="text text_type_h2 text_accented">~ {{ name }} ~</span></h2>
        <p>
            <span class="text text_type_body-reg">&#35; {{ id }} (Researched by&nbsp;</span><!--
         --><a class="text text_type_body-link text_breakable" href="https://objkt.com/profile/{{ account }}" target="_blank">{{ accountFinalName }}</a><!--
         --><span class="text text_type_body-reg">)</span>
        </p>
    </div>
    <div class="description__block">
        <p class="text text_type_body-reg">{{bio}}</p>
    </div>
    <div class="description__block">
        <p class="description__property"><span class="text text_type_body-bold text_accented description__property-name">Occupation:</span><span class="text text_type_body-reg">{{ occupation }}</span></p>
    </div>
    <div class="description__block">
        <p class="description__property"><span class="text text_type_body-bold text_accented description__property-name">Age at death:</span><span class="text text_type_body-reg">{{ ageAtDeath }}</span></p>
        <p class="description__property"><span class="text text_type_body-bold text_accented description__property-name">Cause of death:</span><span class="text text_type_body-reg">{{ causeOfDeath }}</span></p>
    </div>
    {{#if notableWorks}}
        <div class="description__block">
            <p class="description__property"><span class="text text_type_body-bold text_accented description__property-name">Notable works:</span></p>
            <p class="text text_type_body-reg">{{notableWorks}}</p>
        </div>
    {{/if}}
    {{#if legacy}}
        <div class="description__block">
            <p class="description__property"><span class="text text_type_body-bold text_accented description__property-name">Legacy:</span></p>
            <p class="text text_type_body-reg">{{legacy}}</p>
        </div>
    {{/if}}
    {{> family-block }}
    {{> children-block }}
    {{#if (get this "addons") }}
    <div class="description__section">
        <div class="description__block">
            {{#each (get this "hobbies") }}
                <p class="text text_type_body-reg">{{{ description }}}</p>
            {{/each}}
        </div>
        <div class="description__block">
            {{#each (get this "paintingsTokens") }}
                <p><span class="text text_type_body-reg">{{ @root.firstName }} painted </span><a class="text text_type_body-link" href="/#painting/{{id}}">{{metadata.name}}</a></p>
            {{/each}}
        </div>
    </div>
    {{/if}}
</article>