import Plyr from 'plyr';
import sound from './sound';
import mp4Controls from './mp4Controls';
import initMp4Controls from './initMp4Controls';

function initAboutPagePlayer (id) {
  if (!document.getElementById(id)) return;

  let player = new Plyr(`#${id}`, {
    autoplay: false,
    controls: mp4Controls,
    seekTime: 3,
    hideControls: false,
    fullscreen: {
      enabled: false,
    },
    muted: false,
    loop: {
      active: false,
    },
    clickToPlay: true,
  });

  if (sound.isEnabled) {
    sound.enableSound(player);
  }

  initMp4Controls(player);

  player.on("volumechange", updateVolumeSetting);

  function updateVolumeSetting() {
    if (player.muted) {
      sound.disableSound(player);
    } else {
      sound.enableSound(player);
    }
  }
}

export default initAboutPagePlayer;